<script setup>
import { ref } from 'vue'

const emit = defineEmits([
  'sendMqttMessage',
])

// show/hide the dialog
const show = ref(false)

const id = ref(-1)
const name = ref("")

function deleteTeam(item) {
  id.value = item.id
  name.value = item.name
  show.value = true
}

function confirm() {
  // send MQTT message to delete Team
  emit("sendMqttMessage",
    {
      topic: "deleteTeam",
      payload: id.value
    }
  )
  close()
}

function close() {
  show.value = false
}

function reset() {
  id.value = -1
  id.name = ""
}

defineExpose({
  deleteTeam
})

</script>

<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    @click:outside="close()"
    @afterLeave="reset()"
  >
    <v-card>
      <v-card-title class="text-h5">
        Are you sure?
      </v-card-title>
      <v-card-text>
        Delete the team {{ name }} (Id: {{ id }}).
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="close()"
        >Cancel</v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="confirm()"
        >Delete</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
