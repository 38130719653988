<script setup>
import mqtt from 'mqtt';
import { ref } from 'vue';
import { useDisplay } from 'vuetify';

const emit = defineEmits([
  'tournament_delay',
  'tournament_gameday',
  'tournament_roster',
  'tournament_settings',
  'tournament_lineup',
  'state_real_time',
  'state_game_time',
  'state_game_state',
  'state_game_config',
  'state_protocol',
  'command_answer',
  'command_print_answer',
  'mqtt_broker_connected',
  'future_teams',
  'future_tournaments',
])

// Get display size (boolean)
const { mdAndUp } = useDisplay()

const connection_state = ref('Not connected to MQTT broker.')
const connection_error = ref('')

const connection = {
  host: 'uwrhorn-demo.hendrikp.de',
  port: 1886,
  endpoint: '/mqtt',
  clean: true, // Reserved session
  connectTimeout: 4000, // Time out
  reconnectPeriod: 4000, // Reconnection interval
  // Certification Information
  clientId: `frontend_${parseInt(Math.random() * 1e15, 10)}`,
  username: null,
  password: null,
}

const subscriptions = {
  'tournament/delay': { qos: 1 },
  'tournament/gameday': { qos: 1 },
  'tournament/roster': { qos: 1 },
  'tournament/settings': { qos: 1 },
  'tournament/lineup': { qos: 1 },
  'state/real_time': { qos: 0 },
  'state/game_time': { qos: 0 },
  'state/game_state': { qos: 1 },
  'state/game_config': { qos: 1 },
  'state/protocol': { qos: 1 },
  'command/answer': { qos: 1 },
  'command/print_answer': { qos: 1 }
}

var client = { connected: false }

function createConnection() {
  // Connect string, and specify the connection method used through protocol
  // ws unencrypted WebSocket connection
  // wss encrypted WebSocket connection
  const { host, port, endpoint, ...options } = connection
  const connectUrl = `wss://${host}:${port}${endpoint}`
  try {
    client = mqtt.connect(connectUrl, options)
    connection_state.value = `Connecting to ${connectUrl} ...`
  } catch (error) {
    connection_state.value = `mqtt.connect error ${error}`
  }
  client.on('connect', () => {
    connection_state.value = `Successfully connected to ${connectUrl} as ${connection.clientId}`
    connection_error.value = ''
    doSubscribe()
  })
  client.on('reconnect', () => {
    connection_state.value = `Reconnecting to ${connectUrl} ...`
    emit('mqtt_broker_connected', false)
  })
  client.on('error', error => {
    connection_error.value = `Connection failed: ${error}`
    emit('mqtt_broker_connected', false)
  })
  client.on('close', () => {
    connection_state.value = `Disconnected.`
    emit('mqtt_broker_connected', false)
  })
  client.on('disconnect', (packet) => {
    connection_state.value = `Received disconnect packet from broker: ${packet}`
    emit('mqtt_broker_connected', false)
    console.log(packet)
  })
  client.on('offline', () => {
    connection_state.value = `Broker went offline.`
    emit('mqtt_broker_connected', false)
  })
  client.on('message', onMessage)
}

function doSubscribe() {
  client.subscribe(subscriptions, (error, granted) => {
    if (error) {
      console.log('Subscribe to topics error', error)
    }
    else {
      emit('mqtt_broker_connected', true)
      // console.log('Subscribe to topics:', granted)
    }
  })
}

function onMessage(topic, payload) {
  try {
    payload = JSON.parse(payload);
  }
  catch (err) {
    console.log('Could not parse message ', err.message)
  }
  // console.log(topic, payload)
  switch (topic) {
    case 'state/real_time':
      emit('state_real_time', payload)
      break
    case 'state/game_time':
      emit('state_game_time', payload)
      break
    case 'state/game_state':
      emit('state_game_state', payload)
      break
    case 'state/game_config':
      emit('state_game_config', payload)
      break
    case 'state/protocol':
      emit('state_protocol', payload)
      break
    case 'tournament/delay':
      emit('tournament_delay', payload)
      break
    case 'tournament/gameday':
      emit('tournament_gameday', payload)
      break
    case 'tournament/roster':
      emit('tournament_roster', payload)
      break
    case 'tournament/settings':
      console.log(payload)
      emit('tournament_settings', payload)
      break
    case 'tournament/lineup':
      emit('tournament_lineup', payload)
      break
    case 'command/answer':
      emit('command_answer', payload)
      break
    case 'command/print_answer':
      emit('command_print_answer', payload)
      break
    default:
      console.log('Unknown topic: ', topic)
  }
}

function sendMqttMessage(message) {
  console.log(message)
  let topic = message.topic
  let payload = message.payload
  // this is still dummy. It should be handled by the python backend
  if (topic === "addTeam") {
    let id = Math.max(...Object.keys(teams.value)) + 1
    teams.value[id] = payload
  } else if (topic === "deleteTeam") {
    delete teams.value[payload]
  } else if (topic === "editTeam") {
    teams.value[payload.id] = payload.team
  }
}

defineExpose({
  sendMqttMessage,
})

createConnection()



// future MQTT message responses
// #############################

// topic: tournament/<tournament_id>/teams/<team_id>
// payload: data for one team, {name: "", gender: "", ...}
// the teams objects is build from multiple MQTT messages
const teams = ref({
  "11": {
    name: "Hamburg",
    gender: "M",
    nation: "GER",
    players: {
      12: {
        cap: 65,
        name: 'Peter Pan',
        pass: 5555
      },
      121: {
        cap: 22,
        name: 'Peter Pan2',
        pass: 2222
      },
      4: {
        cap: 33,
        name: 'Peter Pan3',
        pass: 3333
      },
      43: {
        cap: 44,
        name: 'Peter Pan4',
        pass: 4444
      },
    },
  },
  "12": {
    name: "Berlin",
    gender: "M",
    nation: "GER",
    players: {},
  },
  "223": {
    name: "Dresden",
    gender: "M",
    nation: "GER",
    players: {},
  },
  "3": {
    name: "Göttingen",
    gender: "M",
    nation: "GER",
    players: {},
  },
  "1": {
    name: "Trelleborg",
    gender: "M",
    nation: "SWE",
    players: {},
  },
  "99": {
    name: "Flensburg",
    gender: "M",
    nation: "GER",
    players: {},
  },
  "15": {
    name: "Bremen",
    gender: "M",
    nation: "GER",
    players: {},
  },
  "21": {
    name: "Hannover",
    gender: "M",
    nation: "GER",
    players: {},
  },
  "151": {
    name: "Berlin",
    gender: "F",
    nation: "GER",
    players: {},
  },
  "211": {
    name: "Hamburg",
    gender: "F",
    nation: "GER",
    players: {},
  },
  "9": {
    name: "Bremen",
    gender: "F",
    nation: "GER",
    players: {},
  },
})
emit('future_teams', teams)

const tournaments = ref({
  1: {
    name: 'Störtebecker 2024',
    use_lineup: false,
    show_penalty_time: false,
    town: "Noch Offen",
    pool: "Noch Offen",
    protocol_file: "",
    german_events: true,
  },
  2: {
    name: '1. BL Nord 23/24: Berlin',
    use_lineup: false,
    show_penalty_time: false,
    town: "",
    pool: "",
    protocol_file: "",
    german_events: true,
  },
  3: {
    name: "DEMO UWR Tournament",
    use_lineup: false,
    show_penalty_time: false,
    town: "",
    pool: "",
    protocol_file: "",
    german_events: false,
  }
})
emit('future_tournaments', tournaments)

</script>

<template>
  <!-- show footer with connection method only on bigger displays -->
  <v-footer
    v-if="mdAndUp"
    :app="true"
    class="font-weight-light text-body-2 text-medium-emphasis"
  >
    {{ connection_state }} {{ connection_error }}
  </v-footer>
</template>
