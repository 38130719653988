<script setup>
import { ref } from 'vue';
import Tournaments from './components/Tournaments.vue';

// MQTT variables
const MQTTConnectionRef = ref(null)

const tournament_delay = ref()
const tournament_gameday = ref()
const tournament_roster = ref()
const tournament_settings = ref({
  name: "No Tournament selected"
})
const tournament_lineup = ref()
const state_real_time = ref('00:00')
const state_game_time = ref()
const state_game_state = ref()
const state_game_config = ref()
const state_protocol = ref()
const command_answer = ref()
const command_print_answer = ref()
const mqtt_broker_connected = ref(false)
// soon to become MQTT variables
const teams = ref({})

// value of selected tab
const selected_tab = ref('teams')

// value of selected tournament for display
const tournaments = ref({})
const selected_tournament = ref()
</script>

<template>
  <v-app>
    <!-- Page navigation -->
    <Navigation
      v-model:selectedTab="selected_tab"
      v-model:selectedTournament="selected_tournament"
      :tournaments="tournaments"
      :state_real_time="state_real_time"
    />

    <!-- main area included if mqtt is connected -->
    <v-main v-if="mqtt_broker_connected">
      <v-window
        v-model="selected_tab"
        class="pa-3"
      >
        <v-window-item value="tournaments">
          <Tournaments />
        </v-window-item>
        <v-window-item value="teams">
          <Teams
            :teams="teams"
            @sendMqttMessage="(message) => MQTTConnectionRef.sendMqttMessage(message)"
          />
        </v-window-item>
        <v-window-item value="games">
          <Games />
        </v-window-item>
        <v-window-item value="protocols">
          <Protocols />
        </v-window-item>
        <v-window-item value="controller">
          <Controller />
        </v-window-item>
      </v-window>
    </v-main>
    <!-- main area included if mqtt is not connected -->
    <v-main v-else>
      <WaitingForConnection />
    </v-main>

    <!-- Footer message with MQTT connection logic -->
    <MQTTConnection
      ref="MQTTConnectionRef"
      @tournament_delay="(payload) => tournament_delay = payload"
      @tournament_gameday="(payload) => tournament_gameday = payload"
      @tournament_roster="(payload) => tournament_roster = payload"
      @tournament_settings="(payload) => tournament_settings = payload"
      @tournament_lineup="(payload) => tournament_lineup = payload"
      @state_real_time="(payload) => state_real_time = payload"
      @state_game_time="(payload) => state_game_time = payload"
      @state_game_state="(payload) => state_game_state = payload"
      @state_game_config="(payload) => state_game_config = payload"
      @state_protocol="(payload) => state_protocol = payload"
      @command_answer="(payload) => command_answer = payload"
      @command_print_answer="(payload) => command_print_answer = payload"
      @mqtt_broker_connected="(conn) => mqtt_broker_connected = conn"
      @future_teams="(t) => teams = t"
      @future_tournaments="(t) => tournaments = t"
    />
  </v-app>
</template>
