<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <v-progress-circular
          color="primary"
          indeterminate
          size="128"
          width="12"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        Connecting to Server...
      </v-col>
    </v-row>
  </v-container>
</template>
