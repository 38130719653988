<script setup>
import { ref, toValue } from 'vue';

const props = defineProps({
  teams: Object
})

const emit = defineEmits([
  'sendMqttMessage',
])

// show/hide the dialog
const show = ref(false)

// fill ou the dialog
const is_new_team = ref(false)
const id = ref(-1)
const name = ref("")
const nation = ref("")
const gender = ref("")
const players = ref([])

function addTeam() {
  reset()
  is_new_team.value = true
  show.value = true
}

function editTeam(item) {
  is_new_team.value = false
  id.value = item.id
  name.value = item.name
  nation.value = item.nation
  gender.value = item.gender
  players.value = getPlayersArray(toValue(props.teams)[item.id].players)
  show.value = true
}

function getPlayersObject(playersArray) {
  return Object.fromEntries(Array.from(
    playersArray,
    (value) => {
      let { id, ...player } = value
      return [id, player]
    }
  ))
}

function getPlayersArray(playersObject) {
  // creates a list of players
  // the list is a deep copy, so that the teams prop is not changed
  return Array.from(
    Object.entries(playersObject),
    ([key, value]) => ({
      id: key,
      cap: value.cap ? value.cap : 0,
      name: value.name ? value.name : "",
      pass: value.pass ? value.pass : 0
    })
  )

}

function save() {
  // This is still a dummy message
  if (is_new_team.value) {
    emit("sendMqttMessage",
      {
        topic: "addTeam",
        payload: {
          name: 'Test',
          gender: 'F',
          nation: 'DEN',
          players: {
            2: { cap: 22, name: "Peter", pass: 1234 }
          }
        }
      }
    )
  } else {
    emit("sendMqttMessage",
      {
        topic: "editTeam",
        payload: {
          id: id.value,
          team: {
            name: name.value,
            gender: gender.value,
            nation: nation.value,
            players: getPlayersObject(players.value)
          }
        }
      }
    )
  }

  close()
}

function addPlayer() {
  console.log("add Player")
}

function close() {
  show.value = false
}

function reset() {
  name.value = ""
  nation.value = ""
  gender.value = ""
  players.value = []
  is_new_team.value = false
}

defineExpose({
  addTeam,
  editTeam
})

</script>

<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    @click:outside="close()"
    @afterLeave="reset()"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ is_new_team ? "Add new team" : `Edit team ${id}` }}
      </v-card-title>
      <v-card-text>
        Enter new values for: <br />
        Name: {{ name }} <br />
        Nation: {{ nation }} <br />
        Gender: {{ gender }} <br />
        Players: <br />
        <v-data-table
          :items="players"
          :items-per-page="-1"
        >
          <template v-slot:no-data>No players</template>
          <template v-slot:bottom>
            <v-btn
              prepend-icon="mdi-plus"
              @click="addPlayer()"
            >
              Add Player
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="close()"
        >Cancel</v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="save()"
        >Save</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
