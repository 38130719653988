<script setup>
import { ref, computed, toValue } from 'vue';

const props = defineProps({
  teams: Object,
})

const emit = defineEmits([
  'sendMqttMessage',
])


// setup data table
const headers = ref([
  { title: 'Id', key: "id" },
  { title: 'Teamname', key: 'name' },
  { title: 'Nation', key: 'nation' },
  { title: 'Gender', key: 'gender' },
  { title: 'Number of Players', key: 'n_players' },
  { title: 'Actions', key: 'actions', sortable: false },
])
const search = ref('')

// create array from object/dict
const table_data = computed(() => {
  if (toValue(props.teams)) {
    return Array.from(
      Object.entries(toValue(props.teams)),
      ([key, value]) => ({
        id: key,
        name: value.name ? value.name : "",
        nation: value.nation ? value.nation : "",
        gender: value.gender ? value.gender : "",
        n_players: value.players ? Object.keys(value.players).length : 0,
      })
    )
  } else {
    return []
  }
})

// dialog settings
const TeamsEditDialog = ref(null)
const TeamsDeleteDialog = ref(null)

</script>


<template>
  <v-data-table
    :headers="headers"
    :items="table_data"
    :search="search"
    :sort-by="[{ key: 'name', order: 'asc' }]"
    :items-per-page="-1"
  >
    <template v-slot:top>
      <TableToolbar
        v-model:search="search"
        @addTeam="TeamsEditDialog.addTeam()"
      />
    </template>

    <!-- render content of actions column -->
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="me-4"
        @click="TeamsEditDialog.editTeam(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon @click="TeamsDeleteDialog.deleteTeam(item)">
        mdi-delete
      </v-icon>
    </template>

    <!-- show 'Add Team' button, when Teams list is empty -->
    <template v-slot:no-data>
      <v-btn
        prepend-icon="mdi-plus"
        @click="TeamsEditDialog.addTeam()"
      >
        Add Team
      </v-btn>
    </template>

    <template v-slot:bottom>
      <v-toolbar
        v-if="table_data.length >= 10"
        color="#fff"
        density="compact"
      >
        <v-btn
          prepend-icon="mdi-plus"
          variant="text"
          @click="TeamsEditDialog.addTeam()"
        >
          Add Team
        </v-btn>
      </v-toolbar>
    </template>
  </v-data-table>

  <!-- Dialog to delete team -->
  <TeamsDelete
    ref="TeamsDeleteDialog"
    @sendMqttMessage="(message) => emit('sendMqttMessage', message)"
  />

  <!-- Dialog to edit team -->
  <TeamsEdit
    ref="TeamsEditDialog"
    :teams="teams"
    @sendMqttMessage="(message) => emit('sendMqttMessage', message)"
  />
</template>
