<script setup>

import { DateTime } from 'luxon';
import { ref, computed, defineModel, toValue } from 'vue';
import { useDisplay } from 'vuetify';

// Input/output Properties
const selected_tournament = defineModel('selectedTournament')
const selected_tab = defineModel('selectedTab')
const props = defineProps({
  state_real_time: String,
  tournaments: Object,
})


// Get display size (boolean)
const { xs, smAndUp, smAndDown, mdAndUp, mdAndDown, lgAndUp, xlAndUp } = useDisplay()


// Format real_time iso string
const real_time_formatted = computed(() => DateTime.fromISO(props.state_real_time).toFormat('HH:mm:ss'))

// settings for navigation
// show/hide navigation drawer used on small displays
const drawer = ref(false)
// list of all tabs
const tab_items = [
  {
    tab_title: 'Tournaments',
    value: 'tournaments',
  },
  {
    tab_title: 'Teams',
    value: 'teams',
  },
  {
    tab_title: 'Games',
    value: 'games',
  },
  {
    tab_title: 'Protocols',
    value: 'protocols',
  },
  {
    tab_title: 'Controller',
    value: 'controller',
  },
]

// compute tournament select items
const tournaments_items = computed(() => {
  if (toValue(props.tournaments)) {
    return Array.from(
      Object.entries(toValue(props.tournaments)),
      ([key, value]) => ({
        value: key,
        title: value.name,
      }
    )
    )
  } else {
    return []
  }
})


</script>

<template>
  <v-app-bar
    elevation="2"
    :density="smAndDown ? 'compact' : 'default'"
    scroll-behavior="hide"
    scroll-threshold="200"
  >

    <!-- Add Icon to open navigation drawer (only on small displays)-->
    <v-app-bar-nav-icon
      :density="smAndDown ? 'compact' : 'default'"
      v-if="mdAndDown"
      @click.stop="drawer = !drawer"
    >
    </v-app-bar-nav-icon>

    <!-- Add Title -->
    <v-app-bar-title>
      <v-select
        v-model="selected_tournament"
        :items="tournaments_items"
        label="Tournament"
        density="compact"
        hide-details="true"
      ></v-select>
    </v-app-bar-title>

    <!-- Navigation tabs at the center for big displays -->
    <v-spacer v-if="xlAndUp" />
    <v-tabs
      v-if="lgAndUp"
      v-model="selected_tab"
      align-tabs="left"
      class="d-none d-lg-flex"
    >
      <v-tab
        v-for="i in tab_items"
        :key="i.value"
        :value="i.value"
        @click.stop="drawer = false"
      >
        {{ i.tab_title }}
      </v-tab>
    </v-tabs>
    <v-spacer v-if="mdAndUp" />


    <!-- Login Button on the right (put into navigation drawer on very small displays)-->
    <v-btn
      v-if="smAndUp"
      prepend-icon="mdi-login"
    >
      Login
    </v-btn>

    <!-- Clock on the right (put into navigation drawer on very small displays)-->
    <v-chip
      v-if="smAndUp"
      class="mx-3"
    >
      {{ real_time_formatted }}
    </v-chip>

  </v-app-bar>

  <!-- Navigation Drawer (only on small displays) -->
  <v-navigation-drawer
    v-if="mdAndDown"
    v-model="drawer"
    location="left"
    temporary
  >
    <v-tabs
      v-model="selected_tab"
      align-tabs="left"
      direction="vertical"
    >
      <v-tab
        v-for="i in tab_items"
        :key="i.value"
        :value="i.value"
        @click.stop="drawer = false"
      >
        {{ i.tab_title }}
      </v-tab>
    </v-tabs>

    <!-- Login in navigation drawer on very small displays -->
    <v-divider v-if="xs"></v-divider>
    <v-list-item
      v-if="xs"
      link
      title="Login"
      prepend-icon="mdi-login"
    ></v-list-item>

    <!-- Clock in navigation drawer on very small displays -->
    <v-divider v-if="xs"></v-divider>
    <v-chip
      v-if="xs"
      class="ma-3"
    >
      {{ real_time_formatted }}
    </v-chip>

  </v-navigation-drawer>
</template>
