<script setup>
import { useDisplay } from 'vuetify';

const search = defineModel('search')
const emit = defineEmits([
  'addTeam'
])

// Get display size (boolean)
const { sm, smAndUp, lgAndUp } = useDisplay()

</script>

<template>
  <v-toolbar
    color="#fff"
    density="compact"
  >
    <v-btn
      prepend-icon="mdi-plus"
      @click="emit('addTeam')"
    >
      Add Team
    </v-btn>

    <v-spacer v-if="lgAndUp"></v-spacer>
    <v-spacer v-if="!sm"></v-spacer>
    <v-text-field
      v-if="smAndUp"
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      hide-details
      single-line
      density="compact"
    ></v-text-field>
  </v-toolbar>
</template>
